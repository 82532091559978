<template>
  <div class="product-wrap case-wrap">
    <Header is-active="case" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-case-b"></div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="txt-box">
          <p>智慧水务平台建设包括业务应用系统、支撑平台、信息系统集成三部分。</p>
          <p>业务应用系统：包括SCADA生产调度系统、管网GIS系统、巡检管理系统、分区计量与漏损管控系统、视频监控系统、水务大数据系统、 企业门户和服务热线系统。</p>
          <p>支撑平台：包括操作系统、数据库、GIS平台、组态软件以及工业数据库软件。</p>
          <p>信息系统集成：完成各类应用系统整合、数据集成、软硬件集成工作。</p>
        </div>
        <div class="auto-img" style="margin: 5% auto;">
          <img src="../../assets/img/case/b/pic_1.png" alt="案例">
        </div>
        <div class="txt-box">
          <p>
            本工程智慧水务平台的建设，通过GIS系统及SCADA系统数据可实现对供水水质、水压、流量的实时监控，管理；通过管网地理信息系统的建设，实现供水管网管线布置地理空间高程位置的可视化，有利于建立供水管网电子图档资料，实现管网维修、抢险准确化管理。</p>
          <p> 综上所述，为进一步提高城区供水管网管理的科技水平和技术含量，优化调度，达到实现水务系统运行管理的科学化、现代化，实现安全生产、节能降耗和效益增加的目的。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>客户价值</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yitihua2"></i></div>
            <p>实现数据管理标准一体化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-pingtaihua"></i></div>
            <p>实现工作管理平台化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-keshihua"></i></div>
            <p>实现管网及设备可视化监管</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yujing"></i></div>
            <p>实现管网及设备安全实时预警</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jieneng1"></i></div>
            <p>实现节能降耗</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-shuzihua"></i></div>
            <p>实现巡检维修数字化</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧水务平台,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧水务平台,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  name: 'Case',
  components: {
    Header,
    Footer
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.case-wrap {
  .banner-case-b {
    background: url("../../assets/img/case/b/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
